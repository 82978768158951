exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-mission-js": () => import("./../../../src/pages/about_us/mission.js" /* webpackChunkName: "component---src-pages-about-us-mission-js" */),
  "component---src-pages-about-us-vision-js": () => import("./../../../src/pages/about_us/vision.js" /* webpackChunkName: "component---src-pages-about-us-vision-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-free-programming-course-js": () => import("./../../../src/pages/projects/free_programming_course.js" /* webpackChunkName: "component---src-pages-projects-free-programming-course-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

